* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
  height: inherit;
}